/** @jsxImportSource @emotion/react */
import { OutputData } from "@editorjs/editorjs";
import { css } from "@mui/material";
import { DateTime } from "luxon";
import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { BusinessCategory } from "src/entities/BusinessCategory";
import { CountryPath } from "src/entities/CountryPath";
import { PublisherResponse } from "src/entities/v2/PublisherInfoResponse";
import createBusinessCategories from "src/utils/createBusinessCategories";
import ArticleDesktopConfirmPreview from "../article/ArticleDesktopConfirmPreview";
import Button from "../common/Button";
import { LightTooltip } from "../common/LightTooltip";

interface Props {
  // common
  publisherResponse?: PublisherResponse;
  country: CountryPath;

  // article information
  publishedAt: DateTime;
  title: string;
  summary: string;
  selectedCategories: string[];
  thumbnailAsDefault: boolean;
  companyInfo: string;
  mediaContact: string;
  outputBody: OutputData;
  businessCategories: BusinessCategory[];

  // Dropzone param
  acceptedFiles: File[];

  // functions
  handleSave: () => Promise<void>;
  handleClickSaveOpen: () => void;
  goToPaymentStep: () => void;
}

const HEADER_HEIGHT = 54;
const CATEGORY_HEADER_HEIGHT = 40;

const ComfirmArticleStep: VFC<Props> = (props: Props) => {
  const [t] = useTranslation();
  return (
    <div css={conentWrapper}>
      <div className="w-100% relative" css={previewArea}>
        <div css={pcView}>
          <div css={blur} />
          {/*
            メモ
            枠はCSSで作成、下のバーみたいなやつだけは画像で作っても良さそう
          */}
          <div className="border-b" css={header}>
            {props.country === "IDN" && (
              <img
                css={headerImage}
                src="/articleEditor/header_image_indonesia.png"
                alt="header_image_indonesia"
              />
            )}
            {props.country === "SGP" && (
              <img
                css={headerImage}
                src="/articleEditor/header_image_singapore.png"
                alt="header_image_singapore"
              />
            )}
            {props.country === "PHL" && (
              <img
                css={headerImage}
                src="/articleEditor/header_image_phillipines.png"
                alt="header_image_phillipines"
              />
            )}
            {props.country === "MYS" && (
              <img
                css={headerImage}
                src="/articleEditor/header_image_malaysia.png"
                alt="header_image_malaysia"
              />
            )}
            {props.country === "THA" && (
              <img
                css={headerImage}
                src="/articleEditor/header_image_thailand.png"
                alt="header_image_thailand"
              />
            )}
            {props.country === "VNM" && (
              <img
                css={headerImage}
                src="/articleEditor/header_image_vietnam.png"
                alt="header_image_vietnam"
              />
            )}
          </div>
          <div css={categoryHeader}>
            <div>{t("create_press_release.confirm.header.sample1")}</div>
            <div>{t("create_press_release.confirm.header.sample2")}</div>
            <div>{t("create_press_release.confirm.header.sample3")}</div>
            <div>{t("create_press_release.confirm.header.sample4")}</div>
            <div>{t("create_press_release.confirm.header.sample5")}</div>
          </div>
          <div css={pcContent}>
            <ArticleDesktopConfirmPreview
              title={props.title}
              publisherName={props.publisherResponse?.publisher?.name}
              publishedAt={props.publishedAt}
              summary={props.summary}
              thumbnail={
                props.thumbnailAsDefault ? props.acceptedFiles[0] : undefined
              }
              body={props.outputBody}
              companyInfo={props.companyInfo}
              mediaContact={props.mediaContact}
              businessCategories={createBusinessCategories(
                props.businessCategories,
                props.selectedCategories
              )}
            />
            <div className="rounded-lg" css={companyInfoWrapper}>
              <div css={companyName}>
                {props.publisherResponse?.publisher?.name}
              </div>
              <div css={contentWrapper}>
                <div css={infoTitle}>
                  {t("create_press_release.confirm.section.url")}
                </div>
                <div css={infoContent}>
                  {props.publisherResponse?.publisher?.website_url}
                </div>
              </div>
              <div css={contentWrapper}>
                <div css={infoTitle}>
                  {t("create_press_release.confirm.section.industry")}
                </div>
                <div css={infoContent}>
                  {props.publisherResponse?.industry_category?.name}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="/articleEditor/pc_footer.png" alt="pc_footer" css={pcImg} />
        <div className="flex justify-end items-center" css={buttonFooter}>
          <Button
            text={t("create_press_release.modal.button.save_as_draft")}
            onClick={() => {
              props.handleSave();
              props.handleClickSaveOpen();
            }}
            className="mr-4"
          />
          {props.acceptedFiles.length === 0 ? (
            <LightTooltip
              placement="top"
              title={t("edit_press_release.content.thumbnail.warning")}
            >
              <span>
                <Button
                  text={t("general.next")}
                  disabled={props.acceptedFiles.length === 0}
                  onClick={() => {
                    props.handleSave();
                    props.goToPaymentStep();
                  }}
                  className="mr-4"
                />
              </span>
            </LightTooltip>
          ) : (
            <Button
              text={
                props.country === "IDN" ||
                props.country === "SGP" ||
                props.country === "MYS"
                  ? t("general.next")
                  : t("general.post")
              }
              disabled={props.acceptedFiles.length === 0}
              onClick={() => {
                props.handleSave();
                props.goToPaymentStep();
              }}
              className="mr-4"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const conentWrapper = css`
  background: #ffffff;
  display: flex;
  height: calc(100vh - 80px - 4rem);
`;

const pcView = css`
  flex-direction: column;
  margin-top: 12px;
  background: #eaeaea;
  width: 60%;
  height: calc(100vh - 100px - 10rem);
  border-top: solid #000000 8px;
  border-left: solid #000000 8px;
  border-right: solid #000000 8px;
  border-radius: 24px 24px 0px 0px;
  backdrop-filter: blur(2px);
`;

const blur = css`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.4);
`;

const pcContent = css`
  padding: 24px;
  height: calc(100% - ${HEADER_HEIGHT}px - ${CATEGORY_HEADER_HEIGHT}px);
  overflow: auto;
  display: flex;
`;

const pcImg = css`
  width: 70%;
  height: 16px;
`;

const previewArea = css`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`;

const header = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 24px;
  background: #ffffff;
  border-radius: 24px 24px 0px 0px;
  height: ${HEADER_HEIGHT}px;
`;

const categoryHeader = css`
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px;
  background: #ffffff;
  color: #2d2d2d;
  box-shadow: 0px 5px 5px 2px rgb(0 0 0 / 4%);
  height: ${CATEGORY_HEADER_HEIGHT}px;
`;

const headerImage = css`
  width: 100%;
`;

const buttonFooter = css`
  position: absolute;
  height: 80px;
  width: 100%;
  bottom: 0;
`;

const companyInfoWrapper = css`
  background: #ffffff;
  height: fit-content;
  padding: 12px 0px;
  margin-left: 24px;
  width: 240px;
  z-index: 100;
`;

const companyName = css`
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
`;

const contentWrapper = css`
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;

const infoTitle = css`
  font-size: 8px;
  font-weight: 600;
`;

const infoContent = css`
  font-size: 8px;
  font-weight: 400;
  overflow-wrap: anywhere;
  margin-left: 8px;
`;

export default ComfirmArticleStep;

import { useSnackbar } from "notistack";
import { useState, VFC } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CountryPath } from "src/entities/CountryPath";
import { PublisherResponse } from "src/entities/v2/PublisherInfoResponse";
import { useFetch } from "src/hooks/useFetch";
import { checkSubscripted } from "src/utils/checkSubscripted";
import getBrowserLanguage, {
  AvailLanguage,
} from "src/utils/getBrowserLanguage";
import { getCredit } from "src/utils/getCredit";
import { useAuth } from "../hooks/useAuth";
import DashboardLayout from "./layouts/DashboardLayout";
import SignInLayout from "./layouts/SignInLayout";
import AdditionalServicePage from "./pages/AdditionalServicePage";
import AnalyticsPage from "./pages/AnalyticsPage";
import CreateArticlePage from "./pages/CreateArticlePage";
import CreditsmanagementPage from "./pages/CreditsManagementPage";
import DashboardPage from "./pages/DashboardPage";
import EditArticlePage from "./pages/EditArticlePage";
import EditPublisherInfoPage from "./pages/EditPublisherInfoPage";
import ReportPage from "./pages/ReportPage";
import SignInPage from "./pages/SignInPage";

const Root: VFC = () => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [path, setPath] = useState<string>(getPath());

  const url = decodeURIComponent(window.location.href);
  const urlParam = new URLSearchParams(url.split("?")[1]).get("country");
  const queryCountry = getCountry(urlParam);
  const [country, setCountry] = useState<CountryPath>(
    queryCountry === "" ? "IDN" : queryCountry
  );

  const [lang, setLang] = useState<AvailLanguage>(getBrowserLanguage());

  const {
    data: publisherResponse,
    isLoading: isPublisherLoading,
    refetch: refetchPublisher,
  } = useFetch<PublisherResponse>({
    url: `${process.env.REACT_APP_DOMAIN_V2}/api/v2/publisher`,
    skip: !auth.isAuth,
    onError: () => {
      enqueueSnackbar(
        "An unexpected error has occurred. Please sign in again.",
        {
          variant: "error",
        }
      );
      auth.apiError();
    },
  });

  return auth.loading ? (
    <div>Loading...</div>
  ) : (
    <Router>
      <Routes>
        {auth?.isAuth ? (
          <>
            <Route
              path="/"
              element={
                <DashboardLayout
                  signout={auth.signout}
                  lang={lang}
                  country={country}
                  setCountry={setCountry}
                  setLang={setLang}
                  credits={getCredit(
                    publisherResponse?.credits,
                    country
                  ).toString()}
                  isPublisherInfoLoading={isPublisherLoading}
                  is_subscripted={checkSubscripted(
                    publisherResponse?.publisher?.subscripted_countries,
                    country
                  )}
                  setPath={setPath}
                />
              }
            >
              <Route
                path="article/:publisherId/:slug/:step"
                element={
                  <EditArticlePage
                    auth={auth}
                    country={country}
                    credits={getCredit(publisherResponse?.credits, country)}
                    refetchPublisher={refetchPublisher}
                  />
                }
              />
              <Route
                path="analytics"
                element={
                  <AnalyticsPage
                    signout={auth.signout}
                    lang={lang}
                    country={country}
                    publisherId={publisherResponse?.publisher?.id}
                  />
                }
              />
              <Route
                path="settings"
                element={
                  <EditPublisherInfoPage auth={auth} country={country} />
                }
              />
              <Route
                path="credits_management"
                element={
                  <CreditsmanagementPage
                    auth={auth}
                    country={country}
                    defaultCountry={
                      publisherResponse?.publisher?.default_country
                    }
                    credits={getCredit(
                      publisherResponse?.credits,
                      country
                    ).toString()}
                    isPublisherInfoLoading={isPublisherLoading}
                    is_subscripted={checkSubscripted(
                      publisherResponse?.publisher?.subscripted_countries,
                      country
                    )}
                  />
                }
              />
              <Route
                path="report"
                element={
                  <ReportPage
                    signout={auth.signout}
                    lang={lang}
                    country={country}
                    publisherId={publisherResponse?.publisher?.id}
                    isPublisherLoading={isPublisherLoading}
                  />
                }
              />
              <Route
                path="article/new"
                element={
                  <CreateArticlePage
                    auth={auth}
                    country={country}
                    publisherResponse={publisherResponse}
                    credits={getCredit(publisherResponse?.credits, country)}
                    refetchPublisher={refetchPublisher}
                  />
                }
              />
              <Route
                path="additional_service"
                element={<AdditionalServicePage />}
              />
            </Route>
            <Route
              path="/"
              element={
                <DashboardLayout
                  signout={auth.signout}
                  lang={lang}
                  country={country}
                  setCountry={setCountry}
                  setLang={setLang}
                  credits={getCredit(
                    publisherResponse?.credits,
                    country
                  ).toString()}
                  isPublisherInfoLoading={isPublisherLoading}
                  is_subscripted={checkSubscripted(
                    publisherResponse?.publisher?.subscripted_countries,
                    country
                  )}
                />
              }
            >
              <Route
                path="dashboard"
                element={
                  <DashboardPage
                    signout={auth.signout}
                    lang={lang}
                    country={country}
                    publisherId={publisherResponse?.publisher?.id}
                    publisherName={publisherResponse?.publisher?.name}
                    isPublisherLoading={isPublisherLoading}
                  />
                }
              />
            </Route>
            <Route path="*" element={<Navigate to={path} />} />
          </>
        ) : (
          <Route path="/" element={<SignInLayout />}>
            <Route path="/" element={<Navigate to="signin" />} />
            <Route
              path="signin"
              element={
                <SignInPage
                  signIn={auth?.signin}
                  signOut={auth?.signout}
                  lang={lang}
                  setPath={setPath}
                  path={path}
                />
              }
            />
            <Route path="*" element={<Navigate to="signin" />} />
          </Route>
        )}
      </Routes>
    </Router>
  );
};

/**
 * Return path
 */
const getPath = () => {
  const url = decodeURIComponent(window.location.href);
  const urlParams = new URLSearchParams(url.split("?")[1]);
  const result = urlParams.get("result");
  const country = urlParams.get("country");

  const urlObject = new URL(url);
  const { pathname } = urlObject;

  if (pathname === "/signin") {
    return "dashboard";
  }
  if (pathname === "/dashboard") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/article/new") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/article/:publisherId/:slug") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/analytics") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/settings") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/credits_management") {
    if (result && country)
      return `${pathname}?result=${result}&country=${country}`;
    if (result) return `${pathname}?result=${result}`;
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/report") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/additional_service") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  return "dashboard";
};

const getCountry = (country: string | CountryPath) => {
  if (
    country === "IDN" ||
    country === "SGP" ||
    country === "MYS" ||
    country === "PHL" ||
    country === "THA" ||
    country === "VNM"
  ) {
    return country;
  }
  return "";
};

export default Root;
